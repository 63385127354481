<template>
  <div>
    <div v-if="existsPhoto"></div>
    <div v-else>
      <div class="letter" :style="{ backgroundColor: randomColor() }">
        {{ getLetterName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    nome: {
      type: String
    }
  },
  data() {
    return {
      existsPhoto: false,
      themeBlock: null
    };
  },
  methods: {
    randomColor() {
      let redColor = Math.round(Math.random() * 200);
      let greenColor = Math.round(Math.random() * 200);
      let blueColor = Math.round(Math.random() * 200);
      return `rgb(${redColor},${greenColor},${blueColor})`;
    },
    composeImg() {
      this.existsPhoto = true;
    },
    composeLetter() {
      this.existsPhoto = false;
    }
  },
  computed: {
    getLetterName() {
      return this.nome ? this.nome.charAt(0).toUpperCase() : "U";
    }
  },
  mounted() {
    if (this.url) {
      this.composeImg();
    } else {
      this.composeLetter();
    }
  }
};
</script>

<style lang="scss" scoped>
.letter {
  display: inline-flex;
  font-size: 20px;
  color: white;
  background-color: rgba(17, 27, 71, 1);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin: 6px;
}
</style>
